<template>
    <v-card flat>
        <v-card-title>
            <div class="d-flex align-center justify-space-between" style="width:100%">
                <div class="d-flex flex-column">
                    <div class="d-flex align-center">
                        <span class="primary--text font-weight-bold">Organization users</span>
                        <v-btn :loading="fetchingOrgUsers" @click="$store.dispatch('orgStore/fetchOrgUsers', $route.params.oid)" icon
                            ><v-icon>refresh</v-icon></v-btn
                        >
                    </div>
                    <span v-if="fetchingOrgUsers" class="caption text-uppercase">Fetching user data.....</span>
                    <span v-else class="caption text-uppercase">{{ orgUsers.length }} users found</span>
                </div>
                <div>
                    <v-btn
                        :disabled="fetchingOrgUsers"
                        v-if="isOrgManager"
                        :to="{ name: 'home-org-invite', params: { oid: $route.params.oid } }"
                        text
                        color="primary"
                    >
                        <v-icon color="primary" small>add</v-icon>
                        <span class="font-weight-bold">Add Organization Member</span>
                    </v-btn>
                    <v-btn :disabled="fetchingOrgUsers || !orgUsers.length" color="primary" @click="expand = !expand" icon>
                        <v-icon>search</v-icon>
                    </v-btn>
                </div>
            </div>
        </v-card-title>
        <v-card-text>
            <div v-if="!fetchingOrgUsers">
                <v-text-field v-show="expand && orgUsers.length" label="Search for a member..." autofocus v-model="search"></v-text-field>
                <v-data-table
                    :headers="filteredHeaders"
                    :items="orgUsers"
                    :items-per-page="100"
                    :footer-props="{ itemsPerPageOptions }"
                    item-key="ucid"
                    :search="search"
                    v-if="orgUsers.length > 0"
                >
                    <template v-slot:item.full_name="{ item }">
                        <div class="d-flex align-center">
                            <v-icon class="mr-1" small>person</v-icon>
                            {{ item.full_name }}
                        </div>
                    </template>
                    <template v-slot:item.role="{ item }">{{ item.role }}</template>
                    <template v-slot:item.status="{ item }">
                        <v-chip small v-if="item.is_active === true" color="success">Active</v-chip>
                        <v-chip small v-else-if="item.is_active === false" color="error">Inactive</v-chip>
                        <v-chip small v-else color="primary">Unknown</v-chip>
                    </template>
                    <template v-slot:item.email="{ item }">{{ item.email }}</template>
                    <template v-slot:item.actions="{ item }">
                        <UserActions
                            :uid="item.uid.toString()"
                            :ucid="item.ucid.toString()"
                            :isActiveUser="item.is_active"
                            :userName="item.full_name"
                            hierarchicalLevel="organization"
                            :role="item.role"
                            :orgName="$store.getters['orgStore/orgLongName']"
                        ></UserActions>
                    </template>
                    <template v-slot:no-data>
                        <v-alert v-if="!fetchingOrgUsers" :value="true" color="info" icon="info" class="mt-3" text> </v-alert>
                    </template>
                </v-data-table>
                <div v-else>
                    <v-banner two-line>
                        <v-avatar slot="icon" color="white" size="60">
                            <v-icon x-large icon="info" color="info">
                                info
                            </v-icon>
                        </v-avatar>
                        <div class="d-flex flex-column">
                            <span class="font-weight-bold primary--text mb-1">No users found</span>
                            <span>
                                Currently this organization has no additional users.
                                <span v-if="isOrgManager"
                                    >You can add new organization members via the
                                    <v-icon small color="primary">add</v-icon>
                                    <span class="font-weight-bold primary--text">ADD ORGANIZATION MEMBER </span>
                                    button above.</span
                                >
                            </span>
                        </div>
                    </v-banner>
                </div>
                <v-alert :value="this.$data.error" color="error" icon="warning" class="mt-4" text>
                    <span class="font-weight-bold">An error has occurred</span>
                    <div>
                        The user could not be revoked. To learn more about user revoke issues, check the troubleshooting documentation
                        <v-btn
                            class="font-weight-bold"
                            small
                            outlined
                            text
                            color="error"
                            href="https://docs.nuvolos.cloud/troubleshooting/authorization-issues/i-cant-remove-a-user"
                            target="_blank"
                            >here</v-btn
                        >
                    </div>
                </v-alert>
            </div>
            <div v-else>
                <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
const UserActions = () => import('@/components/UserActions')
export default {
    components: { UserActions },
    data() {
        return {
            search: '',
            error: false,
            expand: true,
            userName: '',
            userUcid: '',
            headers: [
                { text: 'User name', align: 'left', value: 'full_name' },
                { text: 'Email', align: 'left', value: 'email' },
                { text: 'Role', align: 'left', value: 'role' },
                { text: 'Status', align: 'left', value: 'status' },
                { text: 'Actions', align: 'center', value: 'actions' }
            ],
            itemsPerPageOptions: [-1, 100]
        }
    },
    computed: {
        ...mapState('orgStore', ['orgUsers', 'fetchingOrgUsers']),
        ...mapGetters('snapshotStore', ['isDevelopment']),
        ...mapGetters('orgStore', ['isOrgManager']),
        filteredHeaders() {
            if (this.isOrgManager === true) {
                return this.headers
            }
            return this.headers.slice(0, 3)
        }
    },
    mounted() {
        this.$store.dispatch('orgStore/fetchOrgUsers', this.$route.params.oid)
    }
}
</script>
