var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c("v-card-title", [
        _c(
          "div",
          {
            staticClass: "d-flex align-center justify-space-between",
            staticStyle: { width: "100%" }
          },
          [
            _c("div", { staticClass: "d-flex flex-column" }, [
              _c(
                "div",
                { staticClass: "d-flex align-center" },
                [
                  _c(
                    "span",
                    { staticClass: "primary--text font-weight-bold" },
                    [_vm._v("Organization users")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { loading: _vm.fetchingOrgUsers, icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.$store.dispatch(
                            "orgStore/fetchOrgUsers",
                            _vm.$route.params.oid
                          )
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("refresh")])],
                    1
                  )
                ],
                1
              ),
              _vm.fetchingOrgUsers
                ? _c("span", { staticClass: "caption text-uppercase" }, [
                    _vm._v("Fetching user data.....")
                  ])
                : _c("span", { staticClass: "caption text-uppercase" }, [
                    _vm._v(_vm._s(_vm.orgUsers.length) + " users found")
                  ])
            ]),
            _c(
              "div",
              [
                _vm.isOrgManager
                  ? _c(
                      "v-btn",
                      {
                        attrs: {
                          disabled: _vm.fetchingOrgUsers,
                          to: {
                            name: "home-org-invite",
                            params: { oid: _vm.$route.params.oid }
                          },
                          text: "",
                          color: "primary"
                        }
                      },
                      [
                        _c(
                          "v-icon",
                          { attrs: { color: "primary", small: "" } },
                          [_vm._v("add")]
                        ),
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v("Add Organization Member")
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "v-btn",
                  {
                    attrs: {
                      disabled: _vm.fetchingOrgUsers || !_vm.orgUsers.length,
                      color: "primary",
                      icon: ""
                    },
                    on: {
                      click: function($event) {
                        _vm.expand = !_vm.expand
                      }
                    }
                  },
                  [_c("v-icon", [_vm._v("search")])],
                  1
                )
              ],
              1
            )
          ]
        )
      ]),
      _c("v-card-text", [
        !_vm.fetchingOrgUsers
          ? _c(
              "div",
              [
                _c("v-text-field", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.expand && _vm.orgUsers.length,
                      expression: "expand && orgUsers.length"
                    }
                  ],
                  attrs: { label: "Search for a member...", autofocus: "" },
                  model: {
                    value: _vm.search,
                    callback: function($$v) {
                      _vm.search = $$v
                    },
                    expression: "search"
                  }
                }),
                _vm.orgUsers.length > 0
                  ? _c("v-data-table", {
                      attrs: {
                        headers: _vm.filteredHeaders,
                        items: _vm.orgUsers,
                        "items-per-page": 100,
                        "footer-props": {
                          itemsPerPageOptions: _vm.itemsPerPageOptions
                        },
                        "item-key": "ucid",
                        search: _vm.search
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "item.full_name",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c(
                                  "div",
                                  { staticClass: "d-flex align-center" },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "mr-1",
                                        attrs: { small: "" }
                                      },
                                      [_vm._v("person")]
                                    ),
                                    _vm._v(" " + _vm._s(item.full_name) + " ")
                                  ],
                                  1
                                )
                              ]
                            }
                          },
                          {
                            key: "item.role",
                            fn: function(ref) {
                              var item = ref.item
                              return [_vm._v(_vm._s(item.role))]
                            }
                          },
                          {
                            key: "item.status",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                item.is_active === true
                                  ? _c(
                                      "v-chip",
                                      {
                                        attrs: { small: "", color: "success" }
                                      },
                                      [_vm._v("Active")]
                                    )
                                  : item.is_active === false
                                  ? _c(
                                      "v-chip",
                                      { attrs: { small: "", color: "error" } },
                                      [_vm._v("Inactive")]
                                    )
                                  : _c(
                                      "v-chip",
                                      {
                                        attrs: { small: "", color: "primary" }
                                      },
                                      [_vm._v("Unknown")]
                                    )
                              ]
                            }
                          },
                          {
                            key: "item.email",
                            fn: function(ref) {
                              var item = ref.item
                              return [_vm._v(_vm._s(item.email))]
                            }
                          },
                          {
                            key: "item.actions",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c("UserActions", {
                                  attrs: {
                                    uid: item.uid.toString(),
                                    ucid: item.ucid.toString(),
                                    isActiveUser: item.is_active,
                                    userName: item.full_name,
                                    hierarchicalLevel: "organization",
                                    role: item.role,
                                    orgName:
                                      _vm.$store.getters["orgStore/orgLongName"]
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "no-data",
                            fn: function() {
                              return [
                                !_vm.fetchingOrgUsers
                                  ? _c("v-alert", {
                                      staticClass: "mt-3",
                                      attrs: {
                                        value: true,
                                        color: "info",
                                        icon: "info",
                                        text: ""
                                      }
                                    })
                                  : _vm._e()
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        2509459426
                      )
                    })
                  : _c(
                      "div",
                      [
                        _c(
                          "v-banner",
                          { attrs: { "two-line": "" } },
                          [
                            _c(
                              "v-avatar",
                              {
                                attrs: {
                                  slot: "icon",
                                  color: "white",
                                  size: "60"
                                },
                                slot: "icon"
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    attrs: {
                                      "x-large": "",
                                      icon: "info",
                                      color: "info"
                                    }
                                  },
                                  [_vm._v(" info ")]
                                )
                              ],
                              1
                            ),
                            _c("div", { staticClass: "d-flex flex-column" }, [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "font-weight-bold primary--text mb-1"
                                },
                                [_vm._v("No users found")]
                              ),
                              _c("span", [
                                _vm._v(
                                  " Currently this organization has no additional users. "
                                ),
                                _vm.isOrgManager
                                  ? _c(
                                      "span",
                                      [
                                        _vm._v(
                                          "You can add new organization members via the "
                                        ),
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              small: "",
                                              color: "primary"
                                            }
                                          },
                                          [_vm._v("add")]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text"
                                          },
                                          [_vm._v("ADD ORGANIZATION MEMBER ")]
                                        ),
                                        _vm._v(" button above.")
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ])
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                _c(
                  "v-alert",
                  {
                    staticClass: "mt-4",
                    attrs: {
                      value: this.$data.error,
                      color: "error",
                      icon: "warning",
                      text: ""
                    }
                  },
                  [
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v("An error has occurred")
                    ]),
                    _c(
                      "div",
                      [
                        _vm._v(
                          " The user could not be revoked. To learn more about user revoke issues, check the troubleshooting documentation "
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "font-weight-bold",
                            attrs: {
                              small: "",
                              outlined: "",
                              text: "",
                              color: "error",
                              href:
                                "https://docs.nuvolos.cloud/troubleshooting/authorization-issues/i-cant-remove-a-user",
                              target: "_blank"
                            }
                          },
                          [_vm._v("here")]
                        )
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            )
          : _c(
              "div",
              [
                _c("v-skeleton-loader", {
                  staticClass: "mx-auto",
                  attrs: { type: "table" }
                })
              ],
              1
            )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }